/* You can add global styles to this file, and also import other style files */
html {
    font-size: 14px;
    /* color:  #005587; */
     color: #212529;
     border: 0;
     margin: 0;
     padding: 0;
}
body {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    /* color:  var(--text-primary); */
    color: #212529;
    margin: 0px;
    background-color: var(--bg-light);
}

body .p-component {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
 }

.wrapper {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.main-wrapper {
    min-height: 70vh;
    margin-top: 0 !important;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.instructions {
    border: 0px !important;
}

.icon-sm {
    font-size: 0.8rem;
}

.location-address-pad {
    padding-left: 0px;
    word-wrap: break-word;
}

@media all and (max-width: 1400px) {
    .location-address-pad {
        padding-left: 10px;
        word-wrap: break-word;
    }
}

.form-floating.required > label:after {

    content:" *";
    font-size: 15px;
    font-weight: bold;
    color:red;

}

.form-check.required > label:after {
  content:" *";
  font-size: 15px;
  font-weight: bold;
  color:red;
}

.required-field::after {
    content: " *";
    font-size: 15px;
    font-weight: bold;
    color: red;        
}

.font-weight-700 {
    font-weight: 700 !important;
}

@media all and (min-width: 992px) {
    .pull-left-50{
        margin-left: -50px;
    }
}

.label-margin-10{
    margin-left: 10px;
}

@keyframes fadeOutUp {
    0% {
       opacity: 1;
       transform: translateY(0);
    }
    100% {
       opacity: 0;
       transform: translateY(-20px);
    }
 }
 @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }

 .fade-top.ng-enter, .fade-top.ng-hide-remove {
    animation: fadeInDown 1s;
  }

  .fade-top.ng-leave {
    animation: fadeOutUp 1s;
  }

  .fade-top.ng-hide-add {
    animation: fadeOutUp 1s;
    display: block !important;
  }

  .link{
    text-decoration: none;
  }

  .link:hover {
    text-decoration: underline;
}


.p-tooltip .p-tooltip-text {
    background-color: rgb(114, 114, 114);
    font-size: 0.85rem;
}

.border-red{
  border: 1px solid #dc3545;
}

.border-red:focus {
  border-color:#dc3545;box-shadow:0 0 0 .25rem rgba(220,53,69,0.25)
}

.ngHide {
    visibility: hidden;
}